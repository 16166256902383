<!-- eslint-disable vue/no-v-html -->
<template>
  <main
    class="prose relative z-20 w-full max-w-none font-light prose-p:font-light"
    :class="{
      '!font-light [&>&]:w-full': !isPdfPage,
      'text-card-primary prose-a:text-card-primary': !textColor
    }"
    :style="style"
    v-html="content"
  ></main>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'

const { content, textColor } = inject('card') as CardType
const isPdfPage = inject('isPdfPage', false)

const style = computed(() => {
  let result: Record<string, string> = {}

  if (textColor) {
    result = { color: textColor, '--tw-prose-links': textColor }
  }

  if (isPdfPage) {
    result.width = '97%'
  }

  return result
})
</script>
