<template>
  <Card class="h-full min-h-[8.5rem]">
    <CardHeader>
      <CardHeaderIcon v-if="card.header.icon" />
      <CardHeaderTitle />
    </CardHeader>
    <CardContent v-if="card.content" />
    <CardButton v-if="card.button" />
  </Card>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'
import Card from './Card.vue'
import CardHeader from './CardHeader.vue'
import CardHeaderIcon from './CardHeaderIcon.vue'
import CardContent from './CardContent.vue'
import CardHeaderTitle from './CardHeaderTitle.vue'
import CardButton from './CardButton.vue'

interface Props {
  card: CardType
}

const props = defineProps<Props>()
provide('card', props.card)
</script>
