<template>
  <div class="card-button mt-2 mt-auto flex w-full justify-end">
    <elements-button v-if="button" v-bind="button">
      {{ button.text }}
    </elements-button>
  </div>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'

const { button } = inject('card') as CardType
</script>
