<template>
  <component
    :is="as || 'div'"
    class="relative flex w-full flex-col items-start justify-start p-2 @md:p-4"
    :class="
      invert
        ? 'bg-card-primary text-card-secondary'
        : 'bg-card-secondary text-card-primary'
    "
    :style="style"
  >
    <slot />

    <CommonIcon
      v-if="backgroundIcon"
      :name="backgroundIcon"
      class="absolute bottom-2 right-4 hidden bg-transparent text-9xl"
      :class="!backgroundIconColor ? 'text-[#E6E6E6]' : ''"
      :style="backgroundIconColor ? { color: backgroundIconColor } : undefined"
      v-bind="iconProps"
    />
  </component>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'
import type { SVGAttributes } from 'vue'

interface Props {
  invert?: boolean
  as?: 'div' | 'a'
  iconProps?: SVGAttributes
}
defineProps<Props>()

const { backgroundIcon, backgroundIconColor, backgroundColor, textColor } =
  inject('card') as CardType

const style = {
  backgroundColor,
  color: textColor
}
</script>
